<template>
  <div style="min-height:100vh;padding:.2rem">
    <div class="header" style="padding-bottom:30px">
      <van-row gutter="10">
        <van-col @click="onClickBack">
          <svg-icon
            :data_iconName="'back-default'"
            :className="'back_icon'"
            style="height: 25px"
          />
          <span style="font-size: 25px; color: white;font-weight:bold">编辑报告</span>
        </van-col>
      </van-row>
    </div>
    <card style="margin-top: 30px">
      <template #content>
        <fieldForm
          :submit="save"
          :modal="report"
          :fieldData="fields"
          v-on:formUpdate="onFormUpdate"
        ></fieldForm>
      </template>
    </card>
  </div>
</template>

<script>
import card from "@/views/components/Card"
import fieldForm from "@/views/components/FieldForm"
import api from "@/services/apis.js"
import {Toast} from "vant"
export default {
  components: {
    card, fieldForm
  },
  data: () => ({
    report: {
      report_type: "",
      report_content: "",
      attachment_url: ""
    },
    fields: [
      {
        title: "报告类别",
        name: "report_type",
        type: "select",
        values: ['日报', '周报'],
        rule: [{required: true}],
        inline: false,
      },
      {
          title: "报告内容",
          name: "report_content",
          type: "textarea",
          rule: [{ required: true, message: "" }],
          inline: false,
          show_word_limit: true,
          rows: 3,
          max_length: 100
        },
        {
          title: "附件",
          name: "attachment_url",
          type: "file",
          rule: [],
          max_count: 1,
          inline: false,
        },
    ]
  }),
  methods: {
    onFormUpdate(data) {
      this.report.report_type = data.report_type
      this.report.attachment_url = data.attachment_url
      this.report.report_content = data.report_content
    },
    save() {
      const _self = this
      const trans_report = this.report
      trans_report.report_type = this.report.report_type == '日报'? 0: 1
      api.add_reports(trans_report).then((res) => {
        if(res.data.status === 200 || res.data.status === 201) {
          _self.$router.push({path: '/xiaoxi/main', query: {tabIndex: 2}})
        } else {
          Toast(res.message)
        }
      })
    },
    onClickBack() {
      this.$router.push({path: '/xiaoxi/main', query: {tabIndex: 2}})
    }
  }
}
</script>

<style>

</style>